<template>
  <h4 class="accordion-header" id="headingThree">
    <button
      type="button"
      class="accordion-button collapsed d-flex flex-row justify-content-between gap-2 stm-btn-accordion-googlePay"
      data-bs-toggle="collapse"
      data-bs-target="#accordion-googlePay"
      aria-expanded="false"
      aria-controls="accordion-googlePay"
      @click="setUpGooglePay"
    >
      <div class="d-flex justify-content-between w-100">
        <div>Google Pay</div>
        <img alt="googlePay supported" class="icon" :src="googlePayLogoSvg" />
      </div>
    </button>
  </h4>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
  <div
    id="accordion-googlePay"
    class="accordion-collapse collapse"
    aria-labelledby="headingOne"
    data-bs-parent="#paymentAccordion"
    @click="$emit('showVuelidate')"
  >
    <div class="alert alert-danger mt-3 mb-0 stm-msg-googlePay-error mx-4" role="alert" v-if="state.purchaseFailed || state.googlePayError">
      <i class="fas fa-exclamation-circle"></i>
      &nbsp;Error
      <hr />
      <p v-if="state.purchaseFailed">
        Unable to process purchase:
        <span v-html="state.failureError"></span>
      </p>
      <div v-if="state.googlePayError">
        <p>
          GooglePay Error: {{ state.googlePayError }}
          <br />
          If this persists, please contact
          <a href="support@speedify.com">support@speedify.com</a>
        </p>
      </div>
    </div>
    <div class="accordion-body card-element justify-content-center">
      <div v-if="disableButton" class="d-flex justify-content-center">
        <p style="color: red">Please complete all form fields before proceeding with checkout.</p>
      </div>
      <div id="google-pay-button-container" />
      <div class="d-flex flex-nowrap justify-content-around w-100 text-center mt-3">
        <div class="small col">
          <img alt="secure" class="icon" :src="lockSvg" />
          Encrypted and secure payments
        </div>
        <div class="small col align-top">
          <img alt="money back" class="icon" :src="clockRotateLeftSvg" />
          30-Day Money Back Guarantee
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, watchEffect, watch, toRefs } from 'vue';
import * as Sentry from '@sentry/vue';
import { createPurchase } from '@/services/backend';
import { useAnalyticsStore } from '@/stores/analytics';
import { useCheckoutStore } from '@/stores/checkout';
import getLocale from '@/services/getLocale';
import { orderCompleteRedirect } from '@/services/orderCompleteURL';

import googlePayLogoSvg from '@/assets/svg/google-brand-logo.svg';
import lockSvg from '@/assets/svg/lock.svg';
import clockRotateLeftSvg from '@/assets/svg/clock-rotate-left.svg';

const analyticsStore = useAnalyticsStore();
const { checkoutData } = useCheckoutStore();

const locale = getLocale();

const props = defineProps({
  disableButton: Boolean,
  formRef: {
    type: Object,
    required: true,
  },
});

const { disableButton } = toRefs(props)

const emit = defineEmits(['paymentProcessing', 'paymentFailed', 'showVuelidate']);

const state = reactive({
  purchaseFailed: false,
  failureError: '',
  googlePayError: '',
  processing: false,
});

const onPaymentAuthorized = async (paymentData) => {
  const { recurlyToken: token } = paymentData;
  state.processing = true;
  emit('paymentProcessing');
  const result = await createPurchase(checkoutData, undefined, token.id, analyticsStore.details, 'googlePay');
  if (result.status === 'success') {
    orderCompleteRedirect(checkoutData.loggedIn);
  } else if (result.error?.errorCode === 'ERROR_MISSING_SPEEDIFY_LICENSE'){
    checkoutData.isPaid = false;
  } else {
    state.purchaseFailed = true;
    state.failureError = result.error.message;
    state.processing = false;
    emit('paymentFailed');
  }
};

let googlePay;
// this googlePay flow provides a static googlePay instance meaning it is not reactive to pricing changes
// so let's recreate it when the total changes, so it always shows the correct amount
watchEffect(() => {
  if (!props.formRef) return;
  googlePay = recurly.GooglePay({
    form: props.formRef.value,
    currency: checkoutData.currency,
    country: 'US', // merchant's country code
    total: checkoutData.totalPrice.toString(),
    googleMerchantId: import.meta.env.VITE_GOOGLE_MERCHANT_ID,
    billingAddressRequired: true,
    callbacks: {
      onPaymentAuthorized,
    },
    braintree: {
      clientAuthorization: import.meta.env.VITE_BRAINTREE_KEY,
    },
    buttonOptions: {
      buttonType: 'checkout',
      buttonRadius: '4px',
      buttonSizeMode: 'fill',
    },
  });

  googlePay.on('ready', (googlePayButton) => {
    const oldGPayButton = document.getElementById('gpay-button-online-api-id');
    if (oldGPayButton) oldGPayButton.remove();
    const gPayButtonContainer = document.getElementById('google-pay-button-container').appendChild(googlePayButton);
    if (disableButton.value) gPayButtonContainer.firstElementChild.style.display = "none";
  });

  googlePay.on('error', function (err) {
    if (err.statusCode === "CANCELED") return;
    Sentry.captureException(err);
    state.googlePayError = err;
  });
});

// we can still manage to dynamically disable the button, but feedback that we need input to proceed is absent
// hide the button and direct them to address vuelidate issues with replacement text instead
watch(disableButton, (newDisableButton, _) => {
  if (newDisableButton === true) {
    document.getElementById('gpay-button-online-api-id').style.display = "none";
    return;
  }
  document.getElementById('gpay-button-online-api-id').style.display = "block";
});
</script>
